export interface PaginationInfo {
    page: number;
    pageSize: number;
    total: number;
}

export const defaultPaginationInfo: PaginationInfo = {
  page: 1,
  pageSize: 10,
  total: 0,
};
