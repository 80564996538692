import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { FloatingPlaceholderDirective } from './floating-placeholder.directive';
import { SpinnerComponent } from './spinner/spinner.component';
import { TruncatedTextComponent } from './truncated-text/truncated-text.component';
import { AdminButtonComponent } from './admin-button/admin-button.component';
import { NavComponent } from './nav';
import { PageContainerComponent } from './page-container/page-container.component';
import { EnumToStringPipe } from '../../pipes';

@NgModule({
  declarations: [
    PageContainerComponent,
    SpinnerComponent,
    FloatingPlaceholderDirective,
    TruncatedTextComponent,
    AdminButtonComponent,
    NavComponent,
    EnumToStringPipe
  ],
  imports: [CommonModule, RouterModule, FontAwesomeModule],
  exports: [
    PageContainerComponent,
    SpinnerComponent,
    FloatingPlaceholderDirective,
    TruncatedTextComponent,
    AdminButtonComponent,
    NavComponent,
    EnumToStringPipe
  ]
})
export class UiModule {}
