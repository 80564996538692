import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ApiError } from '../models';


@Injectable()
export class ApiErrorInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((errorResponse: HttpErrorResponse) => {

        const apiError: ApiError = {
          title: errorResponse.error?.title || 'Error',
          status: errorResponse.status,
          detail: errorResponse.error?.detail,
          errors: errorResponse.error?.errors,
        };

        return throwError(() => apiError);
      })
    );
  }
}
