import { Injectable } from '@angular/core';
import { ApiClient } from '../../services';
import { PublicFile } from '../../models';


@Injectable({
  providedIn: 'root'
})
export class UploadService {

  constructor(
    private apiClient: ApiClient
  ) { }


  public Upload(formData: FormData, directory: string, url: string){
    return this.apiClient.post<PublicFile>(url, formData, {
      reportProgress: true,
      observe: 'events'
    });
  }

}
