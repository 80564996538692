import { Directive, Input, Output, EventEmitter, HostListener } from "@angular/core";

// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: '[copy-clipboard]' })
export class CopyClipboardDirective {

  @Input("copy-clipboard")
  public payload!: string;

  @Output("copied")
  public copied: EventEmitter<string> = new EventEmitter<string>();

  @HostListener("click", ["$event"])
  public async onClick(event: MouseEvent): Promise<void> {
    event.preventDefault();
    if (!this.payload) return;

    try {
      await navigator.clipboard.writeText(this.payload);
      this.copied.emit(this.payload);
    } catch (error) {
      console.error("Copy failed", error);
    }
  }
}
